<!--  -->
<template>
    <div class="homecont" ref='contentRef'>
      <HeadNav></HeadNav>
  
      <div class="homeLun">
        <img class="wecardImg" src="../assets/new/g1.jpg"  alt="lunbo"/>
      </div>
      <div class="homewe">
        <div class="homeweCont">
            <!-- <div class="homeweCont-title">公司简介</div> -->
            <div class="homeweCont-content">
              <strong>Shanghai Junko New Energy Technology Co.</strong> , Ltd., located at Xinda Building, No. 322 Xiancan Road, Changning District, Shanghai, is a high-tech enterprise specializing in the research and development, production, and sales of induction heating technology and semiconductor materials. The company has invested heavily in technology research and development and has demonstrated outstanding professional capabilities in production and sales.
              <div class="homeweCont-font">
                <strong>R&D and Production Base</strong>: The company has established an advanced R&D and production base, equipped with high-standard testing and inspection facilities, as well as modern production lines, ensuring product quality and production efficiency.
              </div>
              <div class="homeweCont-font">
                <strong>Contributions in the Field of Semiconductor Materials</strong>: In the research and development of semiconductor materials, the company is committed to promoting the green energy revolution in the country through innovative material technology and assisting in achieving energy-saving and emission reduction goals. Our goal is to continuously improve technology to bring users a lighter and better future life experience, and to make a positive contribution to global sustainable development.
              </div>

              <div class="homeweCont-font">
                <strong>Leader in Induction Heating Technology</strong>: In the development of induction heating equipment, the company adheres to "energy-saving and efficiency" and "stability and reliability" as core values, continuously promoting the development of product automation, intelligence, and unmanned functions. We are at the forefront of domestic induction heating technology, dedicated to providing customers with more efficient and reliable heating solutions through technological innovation.
              </div>
              <div class="homeweCont-font">
                <strong>Comprehensive Service System</strong>: We provide a comprehensive pre-sales, sales, and after-sales service to ensure that our products and technologies can create greater value for our customers. Through meticulous customer service, we have established good customer relationships and won widespread recognition in the market.
              </div>
              <div class="homeweCont-font">
              Shanghai Junko New Energy Technology Co., Ltd., driven by innovation, with quality as its lifeblood, and service as its guarantee, is committed to becoming a leading enterprise in the field of new energy technology.
              </div>
            </div>
        </div>


        <div class="homeweBanner">
          <!-- <img src="../assets/new/p1.png" />
          <img src="../assets/new/p2.png" /> -->
          <img src="../assets/some/29.jpg" alt="awiper"/>
        </div>

        <div class="homeweCont">
            <div class="homeweCont-title">Corporate culture</div>
            <div class="qiyewenhua">

              <div class="qiyewenhuaBox">
                <img src="../assets/some/10.png" alt="shuoming"/>
                <div>
                  <div class="homeweContnewBox-font-title">Innovation</div>
                  <div class="homeweContnewBox-font-cont">Innovation-driven 、Technology pioneer</div>
                </div>
              </div>
              <div class="qiyewenhuaBox newqiyewenhuaBox">
                <img src="../assets/some/10.png" alt="shuoming2"/>
                <div>
                  <div class="homeweContnewBox-font-title">Quality</div>
                  <div class="homeweContnewBox-font-cont">Quality-oriented 、 Exquisite Craftsmanship</div>
                </div>
              </div>
              <div class="qiyewenhuaBox">
                <img src="../assets/some/10.png" alt="shuoming3"/>
                <div>
                  <div class="homeweContnewBox-font-title">Sustainability</div>
                  <div class="homeweContnewBox-font-cont">Green manufacturing、Environmentally friendly</div>
                </div>
              </div>

            </div>
        </div>


        <div class="homeweCont newBoHeBox">
            <div class="homeweCont-title newBoHe">Partners</div>
            <img src="../assets/some/13.png" alt="shuoming4"/>
        </div>


      </div>
      <Bottom></Bottom>

      <Sidebar></Sidebar>
    </div>
  </template>
  
  <script>
  import HeadNav from "../components/headNav";
  import Bottom from "../components/bottom";
  import Sidebar from "../components/sidebar";
  export default {
    metaInfo: {
      title: 'About Us',
      meta: [
        {
          name: 'About Us',
          content: 'SiC powder,Induction Heating Machine,Induction Heating power supply,water Cooling system,Disk CNC quenching machine tool,quenching machine tool,High precision power supply'
        },
        {
          name: 'description',
          content: ' Shanghai Junko New Energy Technology Co., Ltd. is a high-tech enterprise located in Changning District, Shanghai, focusing on the research and development, production, and sales of induction heating technology and semi-conductor materials. We provide efficient and energy-saving induction heating equipment to help improve industrial production efficiency, focus on innovation in semiconductor materials,promote the green energy revolution, and con-tribute to global sustainable development.'
        }
      ]
    },
    name: "Homewe",
    components: {
      HeadNav,
      Bottom,
      Sidebar,
    },
    data() {
      return {
        windowHeight: (document.documentElement.clientHeight - 100),   //实时屏幕高度
        newList: [
          {
            id: 1,
            name: '高纯sic粉',
            name2: '第三代半导体材料——SIC  高纯度、导电型',
            url: require("../assets/new/c1.jpeg"),
            showOverlay: false,
          }, {
            id: 2,
            name: '回转支承滚道数控机床成套设备',
            name2: 'Inclined vertical raceway CNC',
            url: require("../assets/new/c2.png"),
            showOverlay: false,
          },{
            id: 3,
            name: '双频电源',
            name2: 'Gears, sprockets, slewing supports, etc. need profiling heating;',
            url: require("../assets/new/c3.png"),
            showOverlay: false,
          },{
            id: 4,
            name: '便携式感应加热电源',
            name2: 'The intelligent digital induction heating power supply has a high-speed core processor of 100 megabytes，which can calculate and process all digital signals',
            url: require("../assets/new/c4.png"),
            showOverlay: false,
          },{
            id: 5,
            name: '晶体生长感应加热电源',
            name2: 'Specially designed for crystal growth furnace',
            url: require("../assets/new/c5.png"),
            showOverlay: false,
          },
        ],

        // title: '',
        // keywords: '',
        // description: '这是一篇文章...'
      }
    },
    // metaInfo () {
    //   return {
    //     title: this.title,
    //     meta: [
    //       { vmid: 'keywords', name: 'keywords', content: this.description },
    //       { vmid: 'description', name: 'description', content: this.description }
    //     ]
    //   }
    // },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    filters: {},
    //方法集合
    methods: {
      handleMouseEnter(item) {
        //console.log("111>>>>",JSON.stringify(item.id))
        let idval = item.id
        this.newList.map(it=>{
          if(it.id == idval) {
            it.showOverlay = true
          }else {
            it.showOverlay = false
          }
        })
      },
      handleMouseLeave() {
        this.newList.map(item=>{
          item.showOverlay = false
        })
      },
  
  
      choseClick(item) {
         // console.log(">>>>>>>",JSON.stringify(item))
          this.$router.push({ 
              path: '/pcyaoqwenDetails', 
              query: { 
                  auditID: item.id,  
              } 
          });
      },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
      document.title = "关于我们"      
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {},
    beforeCreate() {}, // 生命周期 - 创建之前
    beforeMount() {}, // 生命周期 - 挂载之前
    beforeUpdate() {}, // 生命周期 - 更新之前
    updated() {}, // 生命周期 - 更新之后
    beforeDestroy() {}, // 生命周期 - 销毁之前
    destroyed() {}, // 生命周期 - 销毁完成
    activated() {
      //console.log(">>>>>",'如果页面有keep-alive缓存功能，这个函数会触发')
    }, // 如果页面有keep-alive缓存功能，这个函数会触发

    
  };
  </script>
  <style lang="less" scoped src="@/styles/pc/home.less"></style>
  <style lang="less" scoped src="@/styles/pc/homewe.less"></style>
  <style lang="less">
    .chanpinBoxTitleTop {
      .el-carousel__item:hover .chanpinBoxContShuo{
          cursor: pointer;
          opacity: 1;
          border: 1px solid rgba(0, 0, 0,.3);
      }
      .is-active {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0,.6);
        border-radius: 5px;
        
      }
      .el-carousel__indicators--outside {
        visibility: hidden;
      }

    }
  
  </style>
  
  
  
  